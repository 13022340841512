import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState, actions } from "../../../store";
import { withRouter, RouteComponentProps } from "react-router";
import {
  IonInput,
  IonText,
  IonSelect,
  IonSelectOption,
  IonHeader,
  IonIcon,
  IonButtons,
  IonMenuButton,
  IonList,
  IonSegmentButton,
  IonButton,
  IonSearchbar,
  IonContent,
  IonRefresher,
  IonRefresherContent,
  IonFab,
  IonFabList,
  IonFabButton,
  IonAlert,
  IonBadge,
  IonLabel,
  IonToolbar,
} from "@ionic/react";
import "./../../Admin.scss";
import { log, LogLevel } from "../../../utils/LogUtil";
import { setGlobal, GlobalKey, getGlobal } from "./../../../utils/GlobalUtil";
import { fetchAPI } from "../../../utils/APIUtil";
import Truncate from "react-truncate";
import {
  SeminarLecture,
  SeminarLectureAttribute,
} from "../../../models/Model.Seminar";
import { UIPopupType } from "../../../store/ui/types";
import { STATUS_CODES } from "http";
import { idText } from "typescript";
import ReactHtmlParser from "react-html-parser";
import Button from "../../../components/atoms/Button";
import { DailyPharmRecruitBizItem } from "./DailyPharmRecruitBizList";
import { BizUserInfo } from "../../../models/Mode.Biz";
import { findClosestStrings } from "../../../utils/StringUtil";

const AdminAPI = {
  DAILY_PHARM_COMPANY_LIST: {
    method: "GET",
    path: "/admin/event",
    contentType: "application/json",
  },
};

type Props = RouteComponentProps<{ tab: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> & {
    recruit: any;
    onClose: () => void;
    onLoadCompanyLists?: () => Promise<void>;
    DPCompanyList?: DailyPharmRecruitBizItem[];
    bizCompanyList?: BizUserInfo[];
  };

type State = {
  similarDPCompanyList: DailyPharmRecruitBizItem[];
  similarBizCompanyList: BizUserInfo[];
};

class DailyPharmRecruitDetail extends Component<Props, State> {
  loadingCompanyList = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      similarDPCompanyList: [],
      similarBizCompanyList: [],
    };
  }

  componentDidMount() {}

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      this.props.onLoadCompanyLists &&
      this.props.DPCompanyList &&
      this.props.bizCompanyList &&
      this.loadingCompanyList
    ) {
      this.loadingCompanyList = false;
      this.analizeSimilarity();
    }
  }

  analizeSimilarity = () => {
    this.props.progressPopup.show();
    this.props.progressPopup.setLabel("분석 시작...");
    if (
      this.props.onLoadCompanyLists &&
      (!this.props.DPCompanyList || !this.props.bizCompanyList) &&
      !this.loadingCompanyList
    ) {
      this.props.progressPopup.setLabel("회사정보 불러오기 시작...");
      this.loadingCompanyList = true;
      this.props.onLoadCompanyLists();
      return;
    }

    this.props.progressPopup.setLabel("유사회사 분석 시작...");
    const similarDPCompanyList = findClosestStrings(
      this.props.recruit,
      this.props.DPCompanyList,
      { field: "company" }
    );
    const similarBizCompanyList = findClosestStrings(
      this.props.recruit,
      this.props.bizCompanyList,
      { field: "companyName" }
    );
    this.setState({
      similarDPCompanyList: similarDPCompanyList,
      similarBizCompanyList: similarBizCompanyList,
    });
    log(
      LogLevel.UI_EVENT,
      "DailyPharmRecruitDetail.analizeSimilarity",
      similarDPCompanyList,
      similarBizCompanyList,
      this.props
    );
    this.props.progressPopup.setLabel("유사회사 분석 완료");
    this.props.progressPopup.hide();
  };

  onRegisterStibee() {}

  render() {
    log(LogLevel.UI_LIFECYCLE, "PollManage.render", this.props, this.state);

    if (!this.props.recruit) return <div />;

    return (
      <div className="admin-container">
        <IonHeader class="common-header" no-border>
          <IonToolbar
            color="common-header-toolbar"
            onClick={this.props.onClose}
          >
            <IonButtons slot="start">
              <IonButton class="common-header-toolbar-button">
                <IonIcon name="arrow-back" mode="ios" />
              </IonButton>
              <IonLabel class="viewer-title">DFR 상세보기</IonLabel>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <Button
            size={"Large"}
            type={"IconWithText"}
            variant={"Contained"}
            color={"Primary"}
            icon="CaretRight"
            right
            onClick={() => {
              window.open(
                "https://recruit.dailypharm.com/Offer/OfferView.php?ID=" +
                  this.props.recruit.code,
                getGlobal(GlobalKey.OS) == "browser" ? "_blank" : "_system"
              );
            }}
          >
            이동하기
          </Button>
          {this.props.recruit &&
            this.props.recruit.type &&
            this.props.recruit.type != "약국" && (
              <div className="common-container-row">
                <div className="common-flex-column common-flex-grow"></div>
                <Button
                  size={"Large"}
                  type={"IconWithText"}
                  variant={"Contained"}
                  color={"Primary"}
                  icon="Plus"
                  right
                  onClick={() => {
                    window.open(
                      "https://recruit.dailypharm.com/Offer/OfferView.php?ID=" +
                        this.props.recruit.code,
                      getGlobal(GlobalKey.OS) == "browser"
                        ? "_blank"
                        : "_system"
                    );
                  }}
                >
                  Stibee 등록
                </Button>
              </div>
            )}
          {this.props.onLoadCompanyLists && (
            <Button
              size={"Large"}
              type={"IconWithText"}
              variant={"Contained"}
              color={"Primary"}
              icon="CaretRight"
              right
              onClick={() => {
                this.analizeSimilarity();
              }}
            >
              유사회사 찾기
            </Button>
          )}
          <div className="common-container">
            {ReactHtmlParser(
              JSON.stringify(this.props.recruit, null, 4)
                .replace(/\n/gi, "<br/>")
                .replace(/\\n/gi, "<br/>")
                .replace(/ /gi, "&nbsp;")
            )}
          </div>
        </IonContent>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  lectures: state.seminar.lectures,
  progressPopup: state.ui.popups[UIPopupType.WAITING_POPUP],
  toastPopup: state.ui.popups[UIPopupType.TOAST_POPUP],
  confirmPopup: state.ui.popups[UIPopupType.CONFIRM_POPUP],
  filePath: state.board.filePath,
});

const mapDispatchToProps = {
  //   logOutUser: () => actions.user.logout(),
  //   refreshBoards: () => actions.board.refreshBoards(),
  loadLecture: (lectureId: number) =>
    actions.seminar.getSeminarLecture(lectureId),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DailyPharmRecruitDetail)
);
